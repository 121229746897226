import React from "react"

import Lottie from "lottie-react"

import { Toast, Row } from "react-bootstrap"

import TickAnimation from "@/toasts/success-tick.json"

function SuccessToast(props) {
    return (
        <>
            {/* Success Toast */}

            <Toast
                className="toast-response shadow-lg"
                // Show toast when success in form submit, reset form
                // state and closes form modal when toast closes.
                show={props.status === "success" ? true : false}
                onClose={props.resetModal}
                delay={3000}
                autohide
                animation
                style={{
                    borderStyle: "solid",
                    borderColor: "#e0e1e2",
                    borderWidth: "0.1rem",
                }}
            >
                <Toast.Header style={{ backgroundColor: "#61BE8C" }}>
                    <strong className="me-auto"></strong>
                </Toast.Header>
                <Toast.Body className="px-6 pb-4">
                    <Row className="justify-content-center">
                        <Lottie
                            animationData={TickAnimation}
                            loop={false}
                            autoplay={true}
                            style={{ width: "80%" }}
                        />
                    </Row>
                    <Row className="justify-content-center mb-3">
                        <h2 className="text-semi-bold text-center mt-1">
                            Message sent
                        </h2>
                        <p className="text-center">
                            A member of our team will get back to you soon
                        </p>
                    </Row>
                </Toast.Body>
            </Toast>

            {/* Success Toast End */}
        </>
    )
}

export default SuccessToast
